import { Flex } from 'antd';
import React from 'react';
import CarouselCard from '../carousel-card/carousel-card.component';
import './carousel-container.styles.scss';

const CarouselContainer = ({ title, titleColor, cards }) => {
	return (
		<div className='carousel-container'>
			<Flex vertical>
				<h2 className='fl-heading'>
					<span
						className={`fl-heading-text`}
						style={{
							color: titleColor,
						}}
					>
						{title}
					</span>
				</h2>
				<div className='slider-with-arrows'>
					<Flex justify='space-around'>
						{cards.map((card, index) => (
							<CarouselCard
								key={index}
								cardTitle={card.title}
								cardDescription={card.description}
								image={card.image}
								link={card.link}
							/>
						))}
					</Flex>
				</div>
			</Flex>
		</div>
	);
};

export default CarouselContainer;

import { Flex } from 'antd';
import React from 'react';
import Event from '../event/Events.component';
import ImageCarousel from '../image-carousel/image-carousel.component';

import './Main.styles.scss';

/***   Assets ****/
import IMAGES from '../imagesVariable/imagesVariable';

const digitalResources = [
	
	{
		img: IMAGES.emerald,
		name: 'Emerald Insight',
		link: 'https://www.emerald.com/insight/'
	},
	{
		img: IMAGES.jstor,
		name: 'JSTOR',
		link: 'https://www.jstor.org/'
	},
	{
		img: IMAGES.springer,
		name: 'Springer Link',
		link: 'https://link.springer.com/'
	},
	{
		img: IMAGES.ebsco,
		name: 'EBSCO',
		link: 'https://www.ebsco.com/'
	},
	
];

const quickLinks = [
	{
		img: IMAGES.citizensCharter,
		name: "Citizen's Charter",
		link: 'https://juniv.edu/discussion/14821/file/14651'
	},
	
	{
		img: IMAGES.idCard,
		name: 'Id Card',
		link: 'https://pis.juniv.edu/'
	},
	
	{
		img: IMAGES.remote,
		name: 'Remote Access',
		link: 'https://my.openathens.net/?passiveLogin=false'
	},
	{
		img: IMAGES.thesis2,
		name: 'Thesis Submission',
		link: 'https://docs.google.com/forms/d/e/1FAIpQLSeKnyU7XnzNpdcAPRe8XflJPbRL2GUg4N49nbJbDsM3Tu_rnw/viewform'
	},
	
	
];

const Main = () => (
	<main>

		<div>
			<Flex  gap="middle" align="center" justify='space-around'>
			
			<Event events={quickLinks} Title={'Quick Links'}  />
				<ImageCarousel
					images={[
						{
							url: IMAGES.banner1,
						},
						{
							url: IMAGES.banner2,
						},
						{
							url: IMAGES.banner3,
						},
					]}
          
				/>
				<Event events={digitalResources} Title='Digital Resources' />
			</Flex>
		</div>
	</main>
);

export default Main;

import React from 'react';
import './Footer.style.scss';

const Footer = () => (
  <footer className="footer">
      <div className="footer__content">
        <div className="footer__social">
          <a href="https://www.facebook.com"><i className="fab fa-facebook"></i></a>
          <a href="https://twitter.com"><i className="fab fa-twitter"></i></a>
          <a href="https://www.flickr.com"><i className="fab fa-flickr"></i></a>
          <a href="https://www.instagram.com"><i className="fab fa-instagram"></i></a>
          <a href="https://www.youtube.com"><i className="fab fa-youtube"></i></a>
          <a href="mailto:info@stpaul.lib.mn.us"><i className="fas fa-envelope"></i></a>
        </div>
        <div className="footer__sections">
          <div className="footer__section">
            <h3>About</h3>
            <ul>
            <li><a href="#mission">Mission & Vision</a></li>
              <li><a href="#hours">Hours & Location</a></li>
              <li><a href="#policies">Policies</a></li>
              <li><a href="#about">About the Library</a></li>
            </ul>
          </div>
          <div className="footer__section">
            <h3>Resources</h3>
            <ul>
              <li><a href="#catalog">Catalog</a></li>
              <li><a href="#classic">Classic Catalog</a></li>
              <li><a href="#digital">Digital Library</a></li>
              <li><a href="#events">Ju Journals</a></li>
              <li><a href="#cards">Institutional Repository</a></li>
            </ul>
          </div>
          <div className="footer__section">
            <h3>Help</h3>
            <ul>
              <li><a href="#ask">Ask A Librarian</a></li>
              <li><a href="#faqs">FAQs</a></li>
              <li><a href="#account">Online Registration</a></li>
            </ul>
          </div>
          
          <div className="footer__section">
            <h3>Necessary Links</h3>
            <ul>
              <li><a href="#ask">Ask A Librarian</a></li>
              <li><a href="#faqs">FAQs</a></li>
              <li><a href="#account">Online Registration</a></li>
              <li><a href="#catalog-help">Catalog and Account Help</a></li>
            </ul>
          </div>
        </div>
        <div className="footer__contact">
          <div className="footer__contact-info">
          
          <button className="footer__contact-button">Contact Us</button> <br /><br />
            <strong>University Library <br /> Jahangirnagar University</strong><br />
            Savar, Dhaka-1342<br />
            <a href="tel:02224491045">02224491045-51 (Ext.1244, 2275)</a><br />
            <a href='e-mail:library@juniv.edu'>library@juniv.edu</a>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <p>© 2024 University Library, Jahangirnagar University</p>
      </div>
    </footer>
);

export default Footer;


import React from 'react';
import BannerButton from '../button/bannerButton.component';
import './smallBanner.style.scss';

const SmallBanner = ({ logo, title, description, buttonText, backgroundColor, weblink }) => {
    const handleButtonClick = () => {
        window.open(weblink, '_blank');
      };
    return (
    <div className="banner" style={{ backgroundColor: backgroundColor }}>
      <img src={logo} alt="logo" className="logo" />
      <div className="text">
        <a href={weblink} target='_blank' rel="noopener noreferrer">{title}</a>
        <p>{description}</p>
      </div>
      <BannerButton onClick={handleButtonClick} >{buttonText}</BannerButton>
    </div>
  );
};

export default SmallBanner;

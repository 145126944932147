
import banner1 from '../../assets/images/banner1.jpg';
import banner2 from '../../assets/images/banner2.jpg';
import banner3 from '../../assets/images/banner3.jpg';
import ebsco from '../../assets/images/digital Library/ebsco.png';
import emerald from '../../assets/images/digital Library/emerald.png';
import jstor from '../../assets/images/digital Library/jstor.png';
import springer from '../../assets/images/digital Library/springer.png';
import citizensCharter from '../../assets/images/quick links/citizen charter_1.png';
import idCard from '../../assets/images/quick links/ID card.png';
import remote from '../../assets/images/quick links/Remote Access.png';
import thesis2 from '../../assets/images/quick links/thesis.png';


const IMAGES = {
    banner1,
    banner2,
    banner3,
    ebsco,
    emerald,
    jstor,
    springer,
    citizensCharter,
    idCard,
    remote,
    thesis2
};

export default IMAGES;

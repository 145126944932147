import { Col, Flex } from 'antd';
import React from 'react';
import './carousel-card.styles.scss';

const CarouselCard = ({ cardTitle, cardDescription, image, link }) => {
	return (
		<div className='carousel-card' onClick={() => window.location.href = link}>
			<div className='card-image-container hvr-bounce-to-bottom'>
				<img
					src={image}
					alt={cardTitle}
					style={{
						width: '100%',
						height: "50%",
						//objectFit: 'cover'
					}}
				/>
			</div>
			<div className='card-text-box'>
				<Col>
					<h3 className='card-column-title'>{cardTitle}</h3>
					<div className='card-description'>{cardDescription}</div>
					<div className='card-tags'>
						<Flex>
						</Flex>
					</div>
				</Col>
			</div>
		</div>
	);
};

export default CarouselCard;

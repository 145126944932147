import React from 'react';
import juLogo from "../../assets/images/logo-ju main.jpg";
import Navigation from '../navigation/Navigation.component';
import './Header.style.scss';
const Header = () => (
  <header>
    <div className="header-content">
      <img src={juLogo} alt="University Library Jahangirnagar University"  className='logo'/>
     <Navigation />
    </div>
  </header>
);

export default Header;

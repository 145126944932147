import { Carousel } from 'antd';
import React from 'react';

const ImageCarousel = ({ images }) => {
	return (
		<div className='image-carousel' style={{
            height: '500px',
			width:'50%'
            
        }}>
			<Carousel arrows autoplay={true} fade={true} easing='linear'>
				{images.map((image) => {
					return (
						<div>
							<img
								alt=''
								src={image.url}
								style={{
									height: '500px',
									width: '100%',
								}}
							/>
						</div>
					);
				})}
			</Carousel>
		</div>
	);
};

export default ImageCarousel;

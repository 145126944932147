import React, { Fragment } from 'react';
import CarouselContainer from '../../components/carousel-container/carousel-container.component';
import Main from '../../components/main/Main.component';
import SearchComponent from '../../components/opac-search/opacSearch.component';
import SmallBanner from '../../components/smallBanner/smallBanner.component';

/****   Assets ******/
import bnCard from '../../assets/images/banner/banner_id_card.svg';
import id_card from '../../assets/images/banner/id_card.svg';
import mobile from '../../assets/images/banner/mobile_icon.svg';
import news from '../../assets/images/banner/news.svg';
import ithenticate from '../../assets/images/download.png';
import openathen from '../../assets/images/Openathence.png';
import repository from '../../assets/images/repository.jpg';

const cardData = [
	{
		title: 'Institutional Repository',
		description: 'Access the latest academic resources.',
		link: 'https://repository.juniv.edu/',
		image: repository
	},
	{
		title: 'JU Journals',
		description: 'Stay updated with new academic journals.',
		link: 'https://journals.juniv.edu/',
		image: openathen
	},
	{
		title: 'University Id Card',
		description: 'Manage your university ID card online.',
		link: 'https://pis.juniv.edu/',
		image: bnCard
	},
	{
		title: 'Remote Access',
		description: 'Access university resources remotely.',
		link: 'https://my.openathens.net/?passiveLogin=false',
		image: openathen
	},
	{
		title: 'Plagiarism',
		description: 'Check your work for plagiarism.',
		link: 'https://www.ithenticate.com/',
		image: ithenticate
	}
];

const Home = () => (
	<Fragment>
		<Main />
		
		<SearchComponent/>
		<SmallBanner
			logo={id_card}
			title='Get a Library Card'
			description='Access all of the great resources that the library has to offer!'
			buttonText='Please collect Library Card from concerned hall office'
			backgroundColor='#1867a5'
			weblink='#'
		/>
		<CarouselContainer
				title="Library Services"
				titleColor="blue"
				cards={cardData}
			/>
		<SmallBanner
		logo={news}
		title='Get University Id Card'
		description='Why You Need a University ID Card: Access, Benefits, and Security'
		buttonText='Get Id Card'
		backgroundColor='#663264'
		weblink='https://pis.juniv.edu/'
	/>
		
	<CarouselContainer
	title="Open Access Resources"
	titleColor="#444444"
	cards={cardData}
/>
		<SmallBanner
		logo={mobile}
		title='Download the JU Library App'
		description='The App is in under process'
		buttonText='Get the app'
		backgroundColor='#1867A5'
		weblink='https://pis.juniv.edu/'
	/>
		

		
	<CarouselContainer
	title="New Arrivals"
	titleColor="blue"
	cards={cardData}
/>
	</Fragment>
);

export default Home;

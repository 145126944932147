import React from "react";
import "./Navigation.styles.scss";

const Navigation = () => (
  <nav className="navigation">
    <ul>
      <li>
        <a href="/">Home</a>
      </li>
      <li>
        <a href="https://juniv.edu">JU Home</a>
      </li>
      <li>
        <a href="/about">About</a>
      </li>
      <li className="dropdown">
        <a href="/services" className="dropbtn">Services</a>
        <div className="dropdown-content">
          <a href="https://opac.juniv.edu" target="_blank"  rel="noreferrer">Library Catalogue</a>
          <a href="https://repository.juniv.edu" target="_blank"  rel="noreferrer">Institutional Repository</a>
          <a href="https://journals.juniv.edu" target="_blank"  rel="noreferrer">JU Journals</a>
          <a href="https://pis.juniv.edu" target="_blank"  rel="noreferrer">Digital ID Card</a>
          <a href="https://my.openathens.net/?passiveLogin=false" target="_blank"  rel="noreferrer">Remote Access</a>
          <a href="https://www.ithenticate.com" target="_blank"  rel="noreferrer">Plagiarism Detection Software</a>
          <a href="https://docs.google.com/spreadsheets/d/1ZZ7ja5YwK34JljQwQE_6K0y5KEG1cYY6/edit?pli=1&gid=729923670#gid=729923670" target="_blank"  rel="noreferrer">Thesis Lists</a>
        </div>
      </li>
	  <li className="dropdown">
        <a href="/services" className="dropbtn">Resources</a>
        <div className="dropdown-content">
          <a href="/service1">Resources 1</a>
          <a href="/service2">Resources 2</a>
          <a href="/service3">Resources 3</a>
        </div>
      </li>
      <li>
        <a href="/digital">Digital Library</a>
      </li>
      <li>
        <a href="/events">Downloads</a>
      </li>
      <li>
        <a href="https://opac.juniv.edu">Log In </a>
      </li>
    </ul>
  </nav>
);

export default Navigation;
